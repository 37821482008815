<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Kegiatan : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Status Kegiatan" label-for="status_kegiatan">
            <validation-provider #default="{ errors }" name="status_kegiatan">
              <v-select
                id="status_kegiatan"
                v-model="filterBySearch.webijadwal_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option"
                placeholder="Status Kegiatan.."
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Tanggal Kegiatan" label-for="tanggal_kegiatan">
            <validation-provider #default="{ errors }" name="tanggal_kegiatan">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal_kegiatan"
                  placeholder="Tangal Webinar.."
                  v-model="filterBySearch.webijadwal_tanggal"
                  type="date"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Judul Kegiatan" label-for="judul_kegiatan">
            <validation-provider #default="{ errors }" name="judul_kegiatan">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="judul_kegiatan"
                  placeholder="Judul Kegiatan.."
                  v-model="filterBySearch.webijadwal_title"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-2 mt-md-0"
            @click="modalAddKegiatan()"
          >
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Tambah Kegiatan</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailKegiatan(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>

          <b-dropdown-item @click="modalUpdateKegiatan(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteDataKegiatan(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showListUsersJoinKegiatan(data.item)">
            <feather-icon icon="UserIcon" size="16" />
            <span class="align-middle ml-50">UsersJoin</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>

    <b-modal id="show-detail-kegiatan" hide-footer>
      <template #modal-title> Detail Webinar </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <tr>
          <td><b>Id :</b></td>
        </tr>
        <p>{{ form.id }}</p>
        <tr>
          <td><b>Judul :</b></td>
        </tr>
        <p>{{ form.webijadwal_title }}</p>
        <tr>
          <td><b>Tanggal :</b></td>
        </tr>
        <p>{{ form.webijadwal_tanggal }}</p>
        <tr>
          <td><b>Limit :</b></td>
        </tr>
        <p>{{ form.webijadwal_limit }}</p>
        <tr>
          <td><b>Status :</b></td>
        </tr>
        <p>{{ form.webijadwal_status }}</p>
        <tr>
          <td><b>Durasi :</b></td>
        </tr>
        <p>{{ form.webijadwal_durasi }}</p>
        <tr>
          <td><b>Link :</b></td>
        </tr>
        <p><a :href="form.webijadwal_link" target="_blank">Lihat Link</a></p>
        <tr>
          <td><b>Password :</b></td>
        </tr>
        <p>{{ form.webijadwal_password }}</p>
        <tr>
          <td><b>Zoom ID :</b></td>
        </tr>
        <p><a :href="form.webijadwal_zoom_id" target="_blank">Lihat Zoom</a></p>
        <tr>
          <td><b>File :</b></td>
        </tr>
        <p v-if="form.webijadwal_file === ''">
          {{ "file tidak di temukan" }}
        </p>
        <p v-else>
          <a :href="form.webijadwal_file" target="_blank">Lihat File</a>
        </p>
        <tr>
          <td><b>Image :</b></td>
        </tr>
        <p v-if="form.webijadwal_img === ''">
          {{ "img tidak ditemukan" }}
        </p>
        <p v-else>
          <a :href="form.webijadwal_img" target="_blank">Lihat Img</a>
        </p>
      </table>
    </b-modal>
    <b-modal
      id="modal-add-webinar"
      title="Tambah Webinar"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeAddWebinar">
        <b-form @submit.prevent>
          <b-form-group label="title" label-for="title ">
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="title"
                  v-model="formCreate.webijadwal_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="title"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="tanggal" label-for="tanggal ">
            <validation-provider
              #default="{ errors }"
              name="tanggal"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal"
                  v-model="formCreate.webijadwal_tanggal"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal"
                  type="date"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="durasi" label-for="durasi ">
            <validation-provider
              #default="{ errors }"
              name="durasi"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="durasi"
                  v-model="formCreate.webijadwal_durasi"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Durasi"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="limit" label-for="limit ">
            <validation-provider
              #default="{ errors }"
              name="limit"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="limit"
                  v-model="formCreate.webijadwal_limit"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Limit"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="link" label-for="link ">
            <validation-provider
              #default="{ errors }"
              name="link"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="link"
                  v-model="formCreate.webijadwal_link"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Link"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="passsword" label-for="passsword ">
            <validation-provider
              #default="{ errors }"
              name="passsword"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="passsword"
                  v-model="formCreate.webijadwal_password"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Password"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="jam" label-for="jam ">
            <validation-provider
              #default="{ errors }"
              name="jam"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="jam"
                  v-model="formCreate.webijadwal_jam"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Jam"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="status" label-for="status">
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <v-select
                id="status"
                v-model="formCreate.webijadwal_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                :options="option"
                placeholder="status.."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="zoom_id" label-for="zoom_id ">
            <validation-provider
              #default="{ errors }"
              name="zoom_id"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="zoom_id"
                  v-model="formCreate.webijadwal_zoom_id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="zoom id"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Image Webinar" label-for="image">
            <validation-provider
              #default="{ errors }"
              name="image"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="image"
                  @change="handlerImage($event)"
                  v-model="imghandler"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="File Webinar" label-for="file">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  id="file"
                  @change="handlerFile($event)"
                  v-model="fileHandler"
                  :file-name-formatter="formatNames"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeAddWebinar"
          >
            Tambah Webinar
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- update webinar -->
    <b-modal
      id="modal-update-webinar"
      title="Edit Webinar"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeEditWebinar">
        <b-form @submit.prevent>
          <b-form-group label="id" label-for="id ">
            <b-form-input id="id" v-model="form.id" placeholder="Id webinar" />
          </b-form-group>
          <b-form-group label="title" label-for="title ">
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="title"
                  v-model="form.webijadwal_title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Title"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="tanggal" label-for="tanggal ">
            <validation-provider
              #default="{ errors }"
              name="tanggal"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal"
                  v-model="form.webijadwal_tanggal"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal"
                  type="date"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="durasi" label-for="durasi ">
            <validation-provider
              #default="{ errors }"
              name="durasi"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="durasi"
                  v-model="form.webijadwal_durasi"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Durasi"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="limit" label-for="limit ">
            <validation-provider
              #default="{ errors }"
              name="limit"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="limit"
                  v-model="form.webijadwal_limit"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Limit"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="link" label-for="link ">
            <validation-provider
              #default="{ errors }"
              name="link"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="link"
                  v-model="form.webijadwal_link"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Link"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="passsword" label-for="passsword ">
            <validation-provider
              #default="{ errors }"
              name="passsword"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="passsword"
                  v-model="form.webijadwal_password"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Password"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="jam" label-for="jam ">
            <validation-provider
              #default="{ errors }"
              name="jam"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="jam"
                  v-model="form.webijadwal_jam"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Jam"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="status" label-for="status">
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <v-select
                id="status"
                v-model="form.webijadwal_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                :options="option"
                placeholder="status.."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="zoom_id" label-for="zoom_id ">
            <validation-provider
              #default="{ errors }"
              name="zoom_id"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="zoom_id"
                  v-model="form.webijadwal_zoom_id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="zoom id"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Image" label-for="image">
            <validation-provider
              #default="{ errors }"
              name="image"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  multiple
                  v-model="form.webijadwal_img"
                  @change="handlerImage($event)"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  accpet="image/*"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <th>Image:</th>
          <td v-if="form.webijadwal_img === ''">
            {{ "img tidak ada" }}
          </td>
          <td v-else>
            <a :href="form.webijadwal_img" target="_blank" alt="img"
              >Tampilkan file</a
            >
          </td>
          <br />
          <br />
          <b-form-group label="file" label-for="file">
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  multiple
                  v-model="form.webijadwal_file"
                  @change="handlerFile($event)"
                  :state="errors.length > 0 ? false : null"
                  :file-name-formatter="formatNames"
                  accpet="image/*"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <th>File:</th>
          <td v-if="form.webijadwal_file === ''">
            {{ "file tidak ada" }}
          </td>
          <td v-else>
            <a :href="form.webijadwal_file" target="_blank" alt="img"
              >Tampilkan file</a
            >
          </td>
          <br />
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeEditWebinar"
          >
            Update Webinar
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        { key: "webijadwal_title", label: "title" },
        { key: "webijadwal_tanggal", label: "tanggal" },
        { key: "webijadwal_tanggal", label: "tanggal" },
        { key: "webijadwal_durasi", label: "durasi" },
        { key: "webijadwal_limit", label: "limit" },
        { key: "webijadwal_status", label: "status" },
        "action",
      ],
      /* eslint-disable global-require */
      items: [],

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      form: {
        id: null,
        webijadwal_title: null,
        webijadwal_tanggal: null,
        webijadwal_durasi: null,
        webijadwal_status: null,
        webijadwal_limit: null,
        webijadwal_link: null,
        webijadwal_password: null,
        webijadwal_jam: null,
        webijadwal_zoom_id: null,
        //property untuk simpan file dan img
        webijadwal_file: null,
        webijadwal_img: null,
      },
      formCreate: {
        webijadwal_title: null,
        webijadwal_tanggal: null,
        webijadwal_durasi: null,
        webijadwal_status: null,
        webijadwal_limit: null,
        webijadwal_link: null,
        webijadwal_password: null,
        webijadwal_jam: null,
        webijadwal_zoom_id: null,
      },
      filterBySearch: {
        webijadwal_status: null,
        webijadwal_title: null,
        webijadwal_tanggal: null,
      },
      imghandler: "",
      fileHandler: "",
      option: ["pendaftaran-dibuka", "pendaftaran-ditutup", "selesai"],
    };
  },
  methods: {
    validateBeforeAddWebinar() {
      this.$refs.validateBeforeAddWebinar.validate().then((success) => {
        if (success) {
          this.saveDataKegiatan();
        }
      });
    },
    validateBeforeEditWebinar() {
      this.$refs.validateBeforeEditWebinar.validate().then((success) => {
        if (success) {
          this.updateDataKegiatan();
        }
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getKegiatan();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
    showDetailKegiatan(item) {
      this.form = item;
      this.$bvModal.show("show-detail-kegiatan");
    },

    showListUsersJoinKegiatan(item) {
      this.usersJoinKegiatan(item);
    },

    modalAddKegiatan() {
      this.$bvModal.show("modal-add-webinar");
    },

    modalUpdateKegiatan(item) {
      this.form = item;
      this.$bvModal.show("modal-update-webinar");
    },

    handlerImage(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerImage(files[0], (result) => {
          this.imghandler = result;
        });
      }
    },

    createHandlerImage(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },

    handlerFile(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerFile(files[0], (result) => {
          this.fileHandler = result;
        });
      }
    },

    createHandlerFile(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },

    async getKegiatan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filterBySearch,
        };
        const { data } = await API.webinar.index(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data webinar");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async usersJoinKegiatan(data) {
      this.$router.push(`/kegiatan/${data.id}/sertifikat`);
    },

    async saveDataKegiatan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        await API.webinar.add({
          ...this.formCreate,
          webijadwal_file: this.fileHandler,
          webijadwal_img: this.imghandler,
        });
        this.refreshPage();
        this.handleMsgSuccess("Berhasil tambah galeri");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "form yang anda isi salah");
      } finally {
        this.$bvModal.hide("modal-add-webinar");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async updateDataKegiatan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        let ids = this.form.id; // get id for update data gallery
        await API.webinar.update(ids, {
          ...this.form,
          webijadwal_file: this.fileHandler //jika di upload pakai file baru jika tidak pakai file lama
            ? this.fileHandler
            : this.form.webijadwal_file,
          webijadwal_img: this.imghandler // jika di upload pakai gambar baru jika tidak pakai gambar lama
            ? this.imghandler
            : this.form.webijadwal_img,
        });
        this.refreshPage();
        this.handleMsgSuccess("Berhasil Update Webinar");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal update galeri");
      } finally {
        this.$bvModal.hide("modal-update-webinar");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteDataKegiatan(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text: "Data webinar yang sudah dihapus, tidak bisa dikembalikan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.webinar.delete(ids.id);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil menghapus data webinar",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "kesalahan update coba ulangi lagi");
          });
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "gagal delete webinar");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getKegiatan();
  },
  watch: {
    currentPage() {
      this.getKegiatan();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
